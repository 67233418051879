/*********************************************
:: Typography
********************************************/

html,
body {
  width: 100%;

  font-family: $font-family-1;
  font-weight: $font-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 30px;

  font-family: $font-family-2;
  font-weight: 700;
  color: #18181B;

  strong {
    color: #18181B;
  }
}

h1,
.h1 {
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.93px;
  color: #18181B;

  @media (min-width: $mediumish) {
    font-size: 55px;
    letter-spacing: 0;
    line-height: 65px;
  }
}

h2,
.h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0;
  color: #18181B;

  @media (min-width: $mediumish) {
    font-size: 45px;
    line-height: 55px;
  }
}

h3,
.h3 {
  font-size: 24px;
  line-height: 36px;

  @media (min-width: $mediumish) {
    font-size: 30px;
    line-height: 42px;
  }
}

h4,
.h4 {
  font-size: 18px;
  line-height: 24px;

  @media (min-width: $mediumish) {
    font-size: 24px;
    line-height: 30px;
  }
}

h5,
.h5 {
  font-size: 16px;
  line-height: 24px;

  @media (min-width: $mediumish) {
    font-size: 18px;
    line-height: 26px;
  }
}

h6,
.h6 {
  font-family: $font-family-1;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  @media (min-width: $mediumish) {
    font-size: 16px;
  }
}
/*******************************************/
//Main

p,
li,
td,
th,
a,
mark,
time,
dl,
label,
address {
  margin: 0 0 20px;

  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #18181B;

  a,
  li,
  p,
  mark,
  cite,
  time {
    font-size: 1em;
  }
}

a {
  margin: 0px;

  font-weight: $font-normal;
  text-decoration: none;
  color: #18181B;

  transition: all 0.5s ease-in-out;

  &:hover {
    color: $color-link-hover;
  }
}

small {
  font-size: 70%;
}

strong,
b {
  font-weight: $font-bold;
}

em,
i {
  font-style: italic;
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin-bottom: 15px;

  background: $grey-2;
  border: none;
  outline: none;
}

/*******************************************/
// Lists

dt {
  font-weight: $font-bold;

  &:after {
    content: ':';
  }
}

dt:after {
  content: ': ';
}

ul li {
  position: relative;
  list-style-type: none;
  text-indent: 0px;
}

ol li {
  list-style-type: decimal;
}

/*******************************************/
// Code

code,
pre,
samp,
kbd,
var {
  margin: 0px;
  padding: 1px 5px 1px 5px;

  color: white;
  background: #2d747e;
  white-space: pre-wrap;
}

pre {
  padding: 10px;
}

/*******************************************/
// Blockquote

blockquote {
  position: relative;
  display: block;
  margin: 35px 0;
  padding: 20px 10% 20px 10%;

  font-size: 20px;
  font-weight: $font-light;
  font-style: italic;
  text-align: center;
  line-height: 30px;
  color: $color-4;

  @media (min-width: $mediumish) {
    padding: 40px 10% 40px 10%;
    font-size: 25px;
    line-height: 35px;
  }
}

/*******************************************/
//Styles for Content Areas (wysiwyg)

.wysiwyg {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: $grey-4;

  img {
    display: block;
    margin: 0 auto 15px auto;
  }

  ul {
    margin: 0 0 20px;

    li {
      list-style-type: disc;
      margin: 0;
    }
  }

  ol {
    margin: 0 0 20px;
    padding: 0 0 0 35px;

    li {
      padding-left: 5px;
      margin: 0;

      &::marker {
        font-size: 16px;
        color: #4a4a4a;
      }
    }
  }

  .text-center, .ql-align-center {
    text-align: center;
  }
  .text-right, .ql-align-right {
    text-align: right;
  }
  .text-justify, .ql-align-justify {
    text-align: justify;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  a {
    color: #007BFF;
  }
}

.leadcopy {
  font-family: $font-family-1;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #4a4a4a;
}

/*******************************************/
//Typekit Trick

.wf-loading h1,
.wf-loading h2,
.wf-loading p,
.wf-loading a {
  visibility: hidden;
}
