/*********************************************
:: Popup Wrap Module
********************************************/
.popup-module {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  width: 100% !important;
  height: 100%;

  .background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
  }

  article {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: 971px;
    max-height: 90%;
    margin: auto;
    padding: 20px;
    transform: translate(-50%, -50%);

    background-color: white;

    overflow: auto;

    @media (min-width: $medium) {
      padding: 40px;
    }

    .exit {
      position: absolute;
      top: 20px;
      right: 20px;

      width: 24px;
      height: 24px;

      cursor: pointer;

      @media (min-width: $medium) {
        top: 40px;
        right: 60px;
      }
    }

    .text {
      padding: 20px;

      @media (min-width: $medium) {
        padding: 20px 0 20px 60px;
      }
    }
  }
}
/********************************************/
