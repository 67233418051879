/*********************************************
:: Buttons
********************************************/

button {
  outline: none;
}

/*******************************************/
// Default Button

.button,
a.button,
button.button {
  display: inline-block;
  height: 60px;
  padding: 16px 40px 16px 39px;

  font-family: $font-family-1;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 24px;
  text-align: center;
  color: $grey-5;
  cursor: pointer;

  background: transparent;
  border: 1px solid $grey-5;
  border-radius: 0;

  transition: all 0.2s ease-in-out;


  &.full {
    width: 100%;
  }

  &.small {
    height: 41px;

    line-height: 19px;
  }

  &.medium {
    height: 60px;

    line-height: 38px;
  }

  &.large {
    height: 91px;
    padding: 22px 30px;

    font-size: 24px;
    font-weight: 600;
    line-height: 40px;

    border-width: 3px;
    border-radius: 45px;
  }

  &.white-border {
    color: white;

    background: transparent;
    border-color: white;
  }

  &.border-grey {
    color: #334f54;

    border-color: #334f54;
  }

  &.border-red {
    color: $color-3;

    border-color: $color-3;
  }

  &.border-green {
    color: $color-2;

    border-color: $color-2;
  }

  &.border-blue {
    color: $color-4;

    border-color: $color-4;
  }

  &:hover,
  &:focus {
    color: white;
    background: $grey-5;
  }
}

/*******************************************/
// Disabled Button Styles

.button-disabled,
.button[disabled],
button[disabled],
button[type='submit'][disabled],
input[type='submit'][disabled],
input[type='button'][disabled],
input[type='reset'][disabled] {
  color: white;
  opacity: 0.5;
  cursor: default;
}
