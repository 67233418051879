/*********************************************
:: Tables                    
********************************************/
table {
  width: 100%;
  margin-bottom: 15px;

  td,
  th {
    padding: 15px 10px 15px 10px;
    font-weight: $font-normal;
    color: $grey-4;
    text-align: left;
  }

  th {
    font-weight: $font-bold;
    background: $grey-1;
  }

  thead {
    width: 100%;
    background: $grey-1;

    td,
    th {
      padding: 5px 10px 5px 10px;
    }
  }

  tfoot {
    border-bottom: 1px solid $grey-4;
    border-top: 1px solid $grey-4;

    td {
      padding: 15px 10px 15px 10px;
    }
  }
}

/*******************************************/
// Compact Table
.compact-table {
  width: auto;

  td,
  th {
    padding: 5px 10px 5px 10px;

    font-size: 0.8em;
    font-weight: $font-normal;
    color: $grey-4;

    text-align: left;
    line-height: 1.2;
    background: none;
    border: 1px solid $grey-2;
  }

  thead {
    width: auto;
    background: none;
  }
}

/*******************************************/
// Table Container
.table-container {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
  margin: 0 0 1em;
}
