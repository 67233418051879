/*********************************************
:: Footer Module
********************************************/
.site-footer {
  position: relative;
  width: 100%;
  min-height: $footer-height-mobile;

  background: #18181B;

  @media (min-width: $medium) {
    min-height: $footer-height-desktop;
  }

  .top-links {
    padding-top: 60px;
    padding-bottom: 98px;

    @media (max-width: $medium) {
      padding: 40px 20px 50px;
    }

    .logo {
      height: 54px;
      margin: 0 0 38px;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 31px;

      @media (max-width: $medium) {
        gap: 20px;
      }

      li {
        margin: 0;

        @media (max-width: $medium) {
          width: calc(50% - 10px);
        }

        a {
          font-size: 15px;
          letter-spacing: 0.09px;
          line-height: 24px;

          color: #FFFFFF;
        }
      }
    }
  }

  .footer-legal {
    border-top: 1px solid rgba(#fff, .25);
    padding: 30px 0 40px;

    .content-max-width {
      @media (max-width: $medium) {
        flex-direction: column-reverse;
        gap: 47px;
      }
    }

    p, a {
      margin: 0;
      color: #FFFFFF;
      font-size: 11.5px;
      letter-spacing: 0;
      line-height: 24px;
      text-transform: uppercase;
    }

    a.attr {

      opacity: 0.3;
    }
  }
}
/********************************************/
