/*********************************************
:: Home Page
********************************************/
.page-home {

  h4 {
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0.1px;
    line-height: 26px;
  }

  .hero-section {

    .slick-slider .slick-next {
      z-index: 1;

      @media (max-width: $smallish) {
        top: 115px;
        transform: none;
      }
    }

    .slick-slider .slick-prev {
      z-index: 1;

      @media (max-width: $smallish) {
        top: 115px;
        transform: none;
      }
    }

    .slick-slider .slick-dots {
      max-width: 1340px;
      padding: 0 20px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 65px;
      text-align: right;

      li button:before {
        background: white;
      }

      @media (max-width: $smallish) {
        bottom: unset;
        top: 235px;
        text-align: center;
      }
    }

    article {
      position: relative;
    }

    img {
      width: 100%;
      min-height: 670px;
      object-fit: cover;

      @media (max-width: $smallish) {
        min-height: 280px;
        height: 280px;
      }
    }

    .box {
      position: absolute;
      bottom: 80px;
      width: 100%;
      left: 0;
      z-index: 2;

      @media (max-width: $smallish) {
        position: static;
        background: black;

        padding: 40px 0 50px;

        .content-max-width {
          text-align: center;
        }
      }

      h1 {
        margin: 0;

        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 38px;
        text-align: center;

        color: #FFFFFF;

        @media (min-width: $medium) {
          font-size: 55px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 65px;
          text-align: left;
        }
      }

      h4 {
        margin: 0 0 15px;

        color: #FFFFFF;

        @media (max-width: $smallish) {
          margin: 0 0 10px;
          text-align: center;
        }
      }

      .button {
        margin: 30px 0 0;
      }
    }
  }

  .section-1 {
    padding: 76px 0 125px;

    @media (max-width: $smallish) {
      padding: 40px 0 50px;
    }

    h4 {
      margin: 0 0 20px;

      @media (max-width: $smallish) {
        margin: 0 0 10px;
        text-align: center;
      }
    }

    h2 {
      margin: 0 0 50px;

      @media (max-width: $smallish) {
        margin: 0 0 40px;
        text-align: center;
      }
    }

    .flex {

      .slider {
        max-width: 640px;
        width: 50%;

        @media (max-width: $mediumer) {
          max-width: 100%;
          width: 100%;
        }

        .image-slider {
          .slick-slide {
            @media (min-width: $large) {
              img {
                height: 420px;
                object-fit: cover;
              }
            }
          }
        }
      }

      .text {
        max-width: 530px;
        width: 41%;
        margin-top: .5px;

        @media (max-width: $mediumer) {
          max-width: 100%;
          width: 100%;
        }

        .wysiwyg {
          margin: 0 0 20px;
          overflow: hidden;
          transition: all 0.5s linear;

          p {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 26px;
          }
        }

        .read-more {
          gap: 7px;

          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 24px;
        }
      }
    }
  }

  .section-2 {
    padding: 79px 0 100px;

    @media (max-width: $smallish) {
      padding: 40px 0 50px;
    }

    h4 {
      margin: 0 0 21px;

      @media (max-width: $smallish) {
        margin: 0 0 10px;
        text-align: center;
      }
    }

    h2 {
      margin: 0 0 60px;

      @media (max-width: $smallish) {
        margin: 0 0 40px;
        text-align: center;
      }
    }

    .column {
      margin: 0;
      padding: 0;

      @media (min-width: $medium) {
        column-count: 2;
        column-gap: 40px;
      }

      @media (min-width: $large) {
        padding: 0 65px 0 0;
        column-gap: 175px;
      }

      li {
        padding-left: 20px;

        font-size: 17px;
        letter-spacing: 0.1px;
        line-height: 26px;

        color: #18181B;
        break-inside: avoid;

        &::before {
          position: absolute;
          top: -2px;
          left: 0;
          content: url('/images/column.svg');
        }
      }
    }
  }

  .dealers-section {
    .content-max-width {
      max-width: 1440px;
      padding: 0;

      article {
        width: calc(50% - 10px);
        padding-bottom: 100px;
        text-align: center;

        @media (max-width: $smallish) {
          width: 100%;
          padding-bottom: 50px;
        }

        img {
          margin: 0 0 50px;
        }

        h4 {
          margin: 0 0 10px;
          text-align: center;
        }

        h3 {
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 38px;
          text-align: center;

          @media (max-width: $smallish) {
            font-size: 22px;
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 32px;
          }
        }
      }
    }
  }

  .updates-section {
    padding: 60px 0 80px;
    background-color: #F9F9F9;

    @media (max-width: $smallish) {
      padding: 40px 0 50px;
    }

    h4 {
      margin: 0 0 20px;

      @media (max-width: $smallish) {
        margin: 0 0 10px;
        text-align: center;
      }
    }

    h2 {
      margin: 0 0 50px;

      @media (max-width: $smallish) {
        margin: 0 0 40px;
        text-align: center;
      }
    }

    .main-update {
      max-width: 640px;
      width: 55%;
      background-color: #FFFFFF;

      @media (max-width: $smallish) {
        max-width: 100%;
        width: 100%;
      }

      img {
        width: 100%;
      }

      .body {
        padding: 30px 40px 29px;

        @media (max-width: $medium) {
          padding: 20px 30px 30px;
        }
      }

      p {
        margin: 0 0 19px;

        font-size: 15px;
        letter-spacing: 0.1px;
        line-height: 24px;

        &.date {
          margin: 0 0 11px;
        }
      }

      h3 {
        margin: 0 0 11px;

        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 32px;
      }

      img {
        width: auto;

      }
    }

    .side-updates {
      flex: 1;

      @media (max-width: $smallish) {
        width: 100%;
      }

      .update-row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 20px;

        &:last-child {
          margin: 0;
        }

        a.image {
          max-width: 350px;
          width: 55%;
          object-fit: cover;

          @media (max-width: $mediumer) {
            max-width: 100%;
            width: 100%;
          }
        }

        .body {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-items: flex-start;
          flex: 1;
          padding: 30px;

          background-color: #FFFFFF;

          img {
            max-width: 100%;
            width: auto;
            margin-top: auto;
          }

          p {
            margin: 0 0 12px;

            font-size: 15px;
            letter-spacing: 0.1px;
            line-height: 24px;
          }

          h3 {
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 0.1px;
            line-height: 24px;
          }
        }
      }
    }

    .button {
      margin: 60px 0 0;

      @media (max-width: $smallish) {
        margin: 40px 0 0;
      }
    }
  }
  /********************************************/

}
