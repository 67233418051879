/*********************************************
:: Media Page
********************************************/
.page-media {

    .media {
        padding: 80px 0 100px;

        p {
            margin: 0 0 11px;
            font-size: 15px;
            letter-spacing: 0.1px;
            line-height: 24px;
        }

        h3 {
            margin: 0 0 10px;
            font-size: 22px;
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 32px;
        }

        h4 {
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 0.1px;
            line-height: 24px;
        }

        .button {
            margin-top: 60px;
        }

        .main-media {
            //max-width: 860px;
            width: 100%;

            @media (max-width: $large) {
                //width: 60%;
            }

            @media (max-width: $smallish) {
                width: 100%;
            }

            .main-container {



              @media (min-width: $medium) {
                margin-bottom: 80px;
              }

            }

            .main-article {
                margin: 0 0 19px;
                width: 100%;

                border: 1px solid #e7e7e9;

              @media (max-width: $large) {
                width: 60%;
              }

              @media (max-width: $smallish) {
                width: 100%;
              }

              @media (min-width: $medium) {
                width: calc(50% - 11px);
                margin-bottom: 0;
              }

                .body {
                    padding: 30px 39px 30px;
                    //border: 1px solid #E7E7E9;

                    background: white;

                    @media (max-width: $mediumer) {
                        padding: 20px;
                    }

                    .date {
                        margin-bottom: 10px;
                    }

                    h3 {
                        margin: 0 0 11px;
                    }

                    p {
                        margin: 0 0 19px;
                    }
                }
            }

          .row-container {
            margin: 0 auto;
            max-width: 860px;
            width: 67%;

            @media (max-width: $large) {
              width: 60%;
            }

            @media (max-width: $smallish) {
              width: 100%;
            }
          }

            .media-row {
                display: flex;
                flex-wrap: wrap;
                margin: 0 0 20px;

                &:last-child {
                    margin: 0;
                }

                &:last-child {
                    margin: 0;
                }

                a {
                    img {
                        width: 350px;
                        // width: 55%;
                        height: 230px;
                        object-fit: cover;

                        @media (max-width: $mediumer) {
                            max-width: 100%;
                            width: 100%;
                        }
                    }
                }


                .body {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    flex: 1;
                    padding: 31px 30px 29px;

                    background-color: #FFFFFF;
                    border: 1px solid #E7E7E9;
                    border-left: 0;

                    @media (max-width: $mediumer) {
                        padding: 20px;
                        border-left: 1px solid #E7E7E9;
                    }

                    img {
                        max-width: 100%;
                        width: auto;
                        margin-top: auto;
                    }

                    p {
                        margin: 0 0 10px;
                    }
                }
            }
        }

        .editor-picks {
            max-width: 420px;
            width: 33%;
            padding: 26px 20px 20px;
            background-color: #F9F9F9;

            @media (max-width: $smallish) {
                width: 100%;
                margin-top: 20px;
            }

            p {
                margin: 0 0 5px;
            }

            h3 {
                margin: 0 0 20px;
            }

            .editor-row {
                margin: 0 0 20px;

                &:last-child {
                    margin: 0;
                }

                .body {
                    padding: 31px 30px 30px;
                    background: #fff;

                    @media (max-width: $mediumer) {
                        padding: 20px;
                    }

                    p {
                        margin: 0 0 11px;
                    }

                    h4 {
                        margin: 0 0 40px;
                    }
                }
            }
        }
    }
}
