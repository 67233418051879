/*********************************************
::Dealer Connect Page
********************************************/
.page-dealer {

    .body-section {
        min-height: 481px;
        padding-top: 80px;
        padding-bottom: 100px;

        p {
            margin: 0 0 10px;
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 26px;
        }

        h3 {
            margin: 0 0 29px;
            font-weight: 600;
            line-height: 38px;
        }
    }
}