/*********************************************
:: Service Detail Page                   
********************************************/
.page-service-detail {

  .body-section {
    padding: 40px 0 60px;

    @media (min-width: $medium) {
      padding: 0 0 60px;
    }

    .top {
        padding: 20px 0 40px;

        @media (min-width: $medium) {
            padding: 76px 0 61px;
        }
    
        .left-anchor {
            display: inline-block;
            width: auto;

            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.2px;
            line-height: 30px;
            color: #232221;

            span {
                display: inline-block;
                vertical-align: middle;
                width: 30px;
            }
        }
    }

    .content {
      width: 100%;
      padding: 0 0 40px;

      @media (min-width: $medium) {
        width: calc(100% - 250px);
        padding: 0 30px 0 0;
      }
      @media (min-width: $mediumish) {
        width: calc(100% - 470px);
        padding: 0 120px 0 0;
      }

      h2 {
        margin-bottom: 43px;

        letter-spacing: -0.64px;
      }
    }

    .hero {
      position: relative;

      align-self: flex-start;
      width: 100%;

      @media (min-width: $medium) {
        width: 250px;
      }
      @media (min-width: $mediumish) {
        width: 470px;
      }

      img {
        width: 100%;
        margin: 0;
      }
    }
  }
  /********************************************/

  .faqs-section {
    position: relative;

    padding: 60px 0;

    background: #f8fbfc;

    .content-max-width {
      max-width: 810px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 69px;
      left: -37px;

      width: 110px;
      height: 164px;

      background: url(/images/watermark-left.png) no-repeat center;
      background-size: cover;

      @media (min-width: $medium) {
        width: 220px;
        height: 328px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 49px;
      right: -114px;
      right: -14px;

      width: 110px;
        height: 164px;
      width: 220px;
      height: 328px;

      background: url(/images/watermark-right.png) no-repeat center;
      background-size: cover;

      @media (min-width: $medium) {
        width: 220px;
        height: 328px;
      }
    }

    .h2 {
      text-align: center;

      img {
        width: 23px;
        margin: 0 auto 13px;
      }
    }

    article {
      margin-bottom: 5px;

      background: white;
      border-radius: 2px;

      .title {
        position: relative;

        padding: 30px;

        cursor: pointer;

        .h4 {
          width: calc(100% - 50px);
          margin: 0;

          color: $color-1;
        }

        .material-icons {
          position: absolute;
          top: 34px;
          right: 30px;
        }
      }

      .content {
          display: none;
          width: 100%;

          @media (min-width: $medium) {
            width: calc(100% - 50px);
            padding: 0 30px 30px;
        }
      }

      .content.active {
        display: block;
      }
    }
  }
  /********************************************/

  .gallery-section {
    padding: 60px 0;

    text-align: center;

    .h2 {
      text-align: center;

      @media (min-width: $medium) {
        margin-bottom: 60px;
      }

      img {
        width: 23px;
        margin: 0 auto 13px;
      }
    }

    .content-max-width {
        max-width: 810px;

        article {
          width: 100%;

          img {
            width: 100%;
          }
        }
    }
  }
  /********************************************/

}
