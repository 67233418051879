/*********************************************
::Brands Page
********************************************/

.page-brands {
    padding-bottom: 100px;

    h3 {
        margin: 80px 0 0;

        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 32px;
        text-align: center;
    }

    .brand-row {
        margin-top: 80px;
    }

    h2 {
        position: relative;
        margin: 0 0 49.5px;
        padding-bottom: 22px;

        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 38px;
        text-align: center;

        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            content: '';
            height: 2px;
            width: 100px;
            opacity: 0.5;
            background-color: #18181B;
            transform: translateX(-50%);
        }
    }

    .flex.flex-wrap {
        width: calc(100% + 1px);
    }

    article {
        position: relative;
        width: calc(100%/6 - 15.83px);
        border: 1px solid #E7E7E9;
        background-color: #FFFFFF;

        @media (max-width: $mediumer) {
            width: calc(100%/3 - 15.83px);
        }

        @media (max-width: $smallish) {
            width: calc(100%/2 - 15.83px);
        }

        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%;
        }

        .content {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {

            }
        }
    }
}