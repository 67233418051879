/*********************************************
:: People Page
********************************************/
.page-people {
    overflow: hidden;

    .body-section {
      padding: 40px 0 40px;

      @media (min-width: $medium) {
        padding: 80px 0 46px;
      }

      .grid {
        margin-bottom: 54px;
      }

      article {
        img {
            width: 100%;
            margin: 0 0 20px;
        }

        h5 {
            margin: 0 0 7px;
            font-size: 17px;
            font-weight: 600;
            letter-spacing: 0.1px;
            line-height: 24px;
        }

        p {
            margin: 0 0 13px;
            font-size: 15px;
            letter-spacing: 0.1px;
            line-height: 24px;
        }

        a {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.1px;
            line-height: 24px;

            cursor: pointer;

            img {
                height: 12px;
                width: 12px;
                margin: 0 0 0 7px;
            }
        }
      }

      .detail {
        position: relative;
        padding: 60px 0;
        margin: 54px 0 16px;
        background-color: rgba(246,246,246,0.55);

        &:before {
            position: absolute;
            content: '';
            width: 50%;
            height: 100%;
            left: -50%;
            top: 0;
            background-color: rgba(246,246,246,0.55);
            z-index: -1;
        }

        &:after {
            position: absolute;
            content: '';
            width: 50%;
            height: 100%;
            right: -50%;
            top: 0;
            background-color: rgba(246,246,246,0.55);
            z-index: -1;
        }

        .close {
            position: absolute;
            top: 60px;
            right: 0;

            @media (max-width: $smallish) {
                top: 20px;
            }
        }

        h2 {
            max-width: 860px;
            margin: 0 auto 20px;

            font-size: 30px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 38px;
            text-align: center;
        }

        h5 {
            max-width: 860px;
            margin: 0 auto 31px;

            font-size: 17px;
            font-weight: normal;
            letter-spacing: 0.1px;
            line-height: 26px;
            text-align: center;
        }
        .wysiwyg {
            max-width: 860px;
            margin: 0 auto;
            text-align: center;

            @media (max-width: $smallish) {
                margin: 40px 0 0;
            }
        }

        p {
            font-size: 15px;
            letter-spacing: 0.1px;
            line-height: 24px;
            text-align: center;
        }
      }
    }
}