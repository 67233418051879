/*********************************************
::Title Module
********************************************/
.title-module {
  position: relative;
  width: 100%;

  img {
    min-height: 360px;
    width: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: #18181B;
  }

  .title {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 2;
    transform: translateY(-50%);

    .content-narrow-width {
      max-width: 895px;

      h4 {
        margin: 0 0 10px;

        font-size: 17px;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 26px;
        text-align: center;

        color: #FFFFFF;
      }

      h1 {
        margin: 0 0 30px;
        font-weight: 600;
        text-align: center;
        color: #FFFFFF;
      }

      hr {
        height: 2px;
        width: 100px;
        margin: 0 auto;
        opacity: 0.5;
        background-color: #FFFFFF;
      }
    }

  }
}
/********************************************/
