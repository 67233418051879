/*********************************************
:: History Page
********************************************/
.page-history {
    padding-bottom: 100px;

    .slider {
        position: relative;
        // padding: 46px 0 0;

        .content-max-width {
            max-width: 1300px;
            // padding: 0 0 49px;
            padding: 0;
            border-bottom: 1px solid #18181B;

            @media (max-width: $mediumish) {
                padding: 0 20px;

            }
        }

        .mySwiper {
            // max-width: 1080px;
            margin: 0 auto;
            padding: 46px 0 49px;

            overflow: hidden;

            .swiper-button-prev {
                position: absolute;
                left: 0;
                top: 50%;
                z-index: 10;

                width: 50px;
                background: white;
                box-shadow: 0px 6px 35px 30px white;

                &:before {
                    content: url('/images/left.png');
                }

                &:after { content: ''; }

                @media (max-width: $mediumish) {
                    left: 20px;
                }
            }

            .swiper-button-next {
                position: absolute;
                right: 0;
                top: 50%;
                z-index: 10;

                width: 50px;
                background: white;
                box-shadow: 0px 6px 35px 30px white;

                &:before {
                    content: url('/images/right.png');
                }

                &:after { content: ''; }

                @media (max-width: $mediumish) {
                    right: 20px;
                }
            }
        }

        .swiper-slide {
            display: flex;
            height: 38px;
            align-items: center;
            justify-content: center;

            font-size: 22px;
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 32px;
            text-align: center;

            opacity: 0.5;
            color: #18181B;
            transition: all 0.3s ease-in-out;

            cursor: pointer;

            &.swiper-slide-active {
                font-size: 30px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 38px;
                opacity: 1;
            }
        }

        &:after {
            content: '';
            height: 15px;
            width: 15px;
            background-color: #18181B;
            position: absolute;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
        }
    }

    .slider-2 {
        padding-top: 50px;

        .thumbsSwiper {
            overflow: hidden;
        }
    }

    .content-narrow-width {
        max-width: 680px;

        .wysiwyg {
            margin: 0 0 50px;
        }

        p {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 26px;
            text-align: center;
        }
    }
}