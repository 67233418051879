/*********************************************
:: About Page
********************************************/
.page-about {

  .section-1 {
    padding: 69px 0 100px;

    @media (max-width: $smallish) {
      padding: 60px 0 80px;
    }

    .content-max-width {
      max-width: 1275px;
    }

    h4 {
      margin: 0 0 20px;
      font-size: 17px;
      font-weight: normal;
      letter-spacing: 0.1px;
      line-height: 26px;
    }

    h2 {
      margin: 0 0 50px;
    }

    .flex {

      .slider {
        max-width: 640px;
        width: 55%;

        @media (max-width: $large) {
          width: 50%;
        }

        @media (max-width: $smallish) {
          width: 100%;
        }
      }

      .text {
        max-width: 530px;
        width: 45%;
        margin-top: -.5px;

        @media (max-width: $large) {
          width: 40%;
        }

        @media (max-width: $smallish) {
          width: 100%;
          margin-top: 20px;
        }

        .wysiwyg {
          margin: 0 0 20px;
          overflow: hidden;

          p {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 26px;
          }
        }

        .read-more {
          gap: 7px;

          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 24px;
        }
      }
    }
  }
  /********************************************/

}
