/*********************************************
:: Contact Page
********************************************/
.page-contact {

  .body-section {
    padding: 80px 0 100px;

    h4 {
      margin: 0 0 20px;

      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 32px;

      &:not(:first-of-type) {
        margin-top: 40px;
      }
    }

    p {
      font-size: 17px;
      letter-spacing: 0.1px;
      line-height: 26px;

      a {
        font-size: 17px;
        letter-spacing: 0.1px;
        line-height: 26px;
        color: #007BFF;
      }
    }

    .text {
      gap: 50px;
      width: 100%;
      @media (max-width: $large) {
        gap: 20px;
        width: 100%;
      }

      @media (max-width: $smallish) {
        width: 100%;
        margin-bottom: 40px;
      }

      > div {
        width: 50%;

        @media (max-width: $large) {
          width: calc(50% - 10px);
        }

        @media (max-width: $smallish) {
          width: 100%;
        }

        img {
          margin: 0 0 60px;
          // width: 100%;
          width: 50%;
        }
      }

      #map {
        width: 100%;
        height: 370px;
        margin: 34px 0 20px;

        background: #ccc;
      }
    }

    .form {
      max-width: 530px;
      width: 42%;

      @media (max-width: $smallish) {
        width: 100%;
      }

      form {
        margin-top: 51px;
      }

      .send {
        margin-top: 18px;
      }
    }

    .text-bottom {
      gap: 50px;

      @media (max-width: $large) {
        gap: 20px;
      }

      @media (max-width: $mediumer) {
        width: 100%;
      }

      @media (max-width: $smallish) {
        width: 100%;
        margin-bottom: 40px;
      }

      > div {
        width: 280px;

        @media (max-width: $large) {
          width: 240px;
        }

        @media (max-width: $mediumer) {
          width: calc(50% - 10px);
        }

        @media (max-width: $smallish) {
          width: 100%;
        }

        img {
          margin: 0 0 60px;
          width: 100%;
        }
      }

      #map {
        width: 100%;
        height: 370px;
        margin: 34px 0 20px;

        background: #ccc;
      }
    }
  }
  /********************************************/

}
