/*********************************************
:: Header Module
********************************************/
.site-header {
  position: relative;
  z-index: 99;

  width: 100%;
  height: $header-height-mobile;

  background: white;

  @media (min-width: $mediumish) {
    height: $header-height-desktop;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.12);

    overflow: initial;
  }

  &.home {
    @media (min-width: $mediumish) {
      position: absolute;
      top: 0;
      background-color: transparent;
      border-bottom: 1px solid rgba(#fff, .5);
    }

    nav {
      .top {
        li {
          a {
            color: white;

            @media (max-width: $mediumish) {
              color: #18181B;
            }
          }
        }
      }
    }
  }

  .logo {
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 1000;

    transition: none;

    @media (min-width: $mediumish) {
      top: 23px;
      z-index: 1002;
    }

    img {
      width: auto;
      height: 46px;
      margin: 0;

      @media (min-width: $mediumish) {
        height: 54px;
      }
    }
  }

  .right {
    position: absolute;
    top: 29px;
    right: 20px;

    @media (min-width: $mediumish) {
      top: 35px;
    }

    .tel {
      display: none;

      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.20px;
      line-height: 50px;
      color: #232221;

      @media (min-width: $medium) {
        display: inline-block;
      }
    }

    .button {
      margin-left: 26px;
    }
  }

  nav {
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 1001;

    width: 0%;
    height: 100vh;
    padding: 88px 20px;

    background: white;

    transition: right 0.25s ease-in-out;

    @media (min-width: $mediumish) {
      width: auto;
      position: relative;
      top: 0;
      right:0;
      height: $header-height-desktop;
      padding: 0;
      background: transparent;
    }

    ul.top {
      margin: 0;
      padding: 0;

      text-align: left;

      @media (min-width: $mediumish) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        height: 100%;
        padding-bottom: 1px;
      }
    }

    li {
      position: relative;
      display: inline-block;
      width: 100%;
      margin: 0;

      border-bottom: 1px solid #E7E7E9;

      &.open {
        svg {
          transform: rotate(180deg);
        }
      }

      &:hover {
        @media (min-width: $mediumish) {
          svg {
            transform: rotate(180deg);
          }
        }

        .dropdown {
          opacity: 1;
        }
      }

      @media (min-width: $mediumish) {
        width: auto;
        display: inline-flex;
        height: 100%;
        border: 0;
      }

      .dropdown {

        @media (min-width: $mediumish) {
          position: absolute;
          left: -20px;
          top: calc(100% + 1px);
          padding: 8px 20px 6px;
          width: 300px;
          background-color: #FFFFFF;
          box-shadow: 0 2px 6px 0 rgba(0,0,0,0.26);
          opacity: 0;
        }

        a {
          padding: 15px 0 13px;

          font-size: 15px;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 24px;
          color: #18181B !important;

          border-bottom: 1px solid #E7E7E9;

          &:first-child {
            padding: 13px 0 13px;
          }

          &:last-child {
            border: 0;
          }
        }
      }

      a {
        position: relative;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 27px 0 11px;

        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.1px;

        color: #18181B;
        cursor: pointer;

        @media (min-width: $mediumish) {
          display: flex;
          gap: 6px;
          align-items: center;
          margin: 0;
          padding: 0;

          font-size: 17px;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 24px;
          color: #18181B;
        }

        &:hover {
          opacity: 0.7;
        }

        &.selected {
          color: $color-2;
          &:hover {
            opacity: 1;
          }
          &:active {
            opacity: 1;
          }
        }

        svg {
          margin-top: 2px;
        }
      }
    }
  }

  .hamburger {
    position: relative;

    display: inline-block;
    vertical-align: top;
    width: 36px;
    height: 22px;
    padding: 0;

    border-radius: 50%;

    transition: right 0.25s ease-in-out;
    z-index: 1002;

    @media (min-width: $mediumish) {
      margin-left: 9px;
      margin-top: 1px;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;

      width: 36px;
      height: 2px;
      background: #18181B;

      // transition: all 0.25s ease-in-out;
    }
    span:nth-of-type(2) {
      top: calc(50% - 3px);
    }
    span:nth-of-type(3) {
      top: calc(50% + 5px);
    }
    &:focus {
      outline: none;
    }
  }
}

.site-header.open {
  nav {
    right: 0;

    width: 300px;
  }

  .hamburger {
    span {
      &:nth-of-type(1) {
        top: 50%;
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }
}
/********************************************/
