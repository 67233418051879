/*********************************************
:: Layout
********************************************/

// Remove blue highlight on click
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  max-width: 100%;
}

html {
  scroll-behavior: smooth;
}

#app {
  overflow-x: hidden;
}

/*******************************************/
// Main <section class="main" role="main">
.main {
  min-height: calc(100vh - #{$header-height-mobile + $footer-height-mobile});

  @media (min-width: $mediumish) {
    min-height: calc(100vh - #{$header-height-desktop + $footer-height-desktop});
  }
}

/*******************************************/
// Content Max Width
.content-max-width {
  position: relative;
  width: 100%;
  max-width: 1340px;
  padding: 0 20px;
  margin: 0 auto;
}

/*******************************************/
// Content Full Width
.content-full-width {
  position: relative;
  max-width: 1210px;
  margin: 0 auto;
  padding: 0;
  clear: both;
}

/*******************************************/
// Narrow Content Max Width
.content-narrow-width {
  position: relative;
  width: 100%;
  max-width: 610px;
  padding: 0 20px;
  margin: 0 auto;
}

/*******************************************/
// Breakpoints
.mob {
  @media (min-width: $mediumish) {
    display: none !important;
  }
}
.desktop {
  @media (max-width: $mediumish) {
    display: none !important;
  }
}

/*******************************************/
// Flex
// .flex {
//   display: flex;
//   justify-content: flex-start;
//   align-items: flex-start;
//   flex-flow: row wrap;

//   &.flex-end {
//     justify-content: flex-end;
//   }
//   &.flex-around {
//     justify-content: space-around;
//   }
//   &.flex-between {
//     justify-content: space-between;
//   }
//   &.flex-center {
//     justify-content: center;
//   }
//   &.align-center {
//     align-items: center;
//   }
//   &.align-baseline {
//     align-items: baseline;
//   }
// }

// .md {
//   @media (min-width: $medium) {
//     &\:flex {
//       display: flex;
//       justify-content: flex-start;
//       align-items: flex-start;
//       flex-flow: row wrap;
//     }
//     &\:flex-end {
//       justify-content: flex-end;
//     }
//     &\:flex-around {
//       justify-content: space-around;
//     }
//     &\:flex-between {
//       justify-content: space-between;
//     }
//     &\:flex-center {
//       justify-content: center;
//     }

//     &\:align-center {
//       align-items: center;
//     }
//     &\:align-baseline {
//       align-items: baseline;
//     }
//   }
// }

// .mdish {
//   @media (min-width: $mediumish) {
//     &\:flex {
//       display: flex;
//       justify-content: flex-start;
//       align-items: flex-start;
//       flex-flow: row wrap;
//     }
//     &\:flex-end {
//       justify-content: flex-end;
//     }
//     &\:flex-around {
//       justify-content: space-around;
//     }
//     &\:flex-between {
//       justify-content: space-between;
//     }
//     &\:flex-center {
//       justify-content: center;
//     }

//     &\:align-center {
//       align-items: center;
//     }
//     &\:align-baseline {
//       align-items: baseline;
//     }
//   }
// }

// .lg {
//   @media (min-width: $large) {
//     &\:flex {
//       display: flex;
//       justify-content: flex-start;
//       align-items: flex-start;
//       flex-flow: row wrap;
//     }
//     &\:flex-end {
//       justify-content: flex-end;
//     }
//     &\:flex-around {
//       justify-content: space-around;
//     }
//     &\:flex-between {
//       justify-content: space-between;
//     }
//     &\:flex-center {
//       justify-content: center;
//     }

//     &\:align-center {
//       align-items: center;
//     }
//     &\:align-baseline {
//       align-items: baseline;
//     }
//   }
// }
/*******************************************/

.slick-slider .slick-prev {
  left: 10px;
  top: calc(50% - 2px);
}

.slick-slider .slick-next:before {
  content: url('/images/right.png');
  font-size: 50px;
}

.slick-slider .slick-next {
  right: 10px;
  top: calc(50% - 2px);
}

.slick-slider .slick-prev:before {
  content: url('/images/left.png');
  font-size: 50px;
}

.slick-slider .slick-dots {
  bottom: -45px;

  li {
    height: 8px;
    width: 8px;
    margin: 0 7px;

    button {
      height: 8px;
      width: 8px;
      padding: 0;

      &:before {
        content: '';
        height: 8px;
        width: 8px;
        opacity: 0.3;
        background-color: #000000;
        border-radius: 50%;
      }
    }
  }
}

.slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
}

.form-alert {
  position: fixed;
  top: 10px; right: 10px;
  height: 88px;
  width: 330px;
  padding: 30px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;

  border-radius: 2px;
  background-color: #18181B;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
  z-index: 100;

  span {
    margin-top: 1px;
    flex: 1;

    font-size: 15px;
    letter-spacing: 0.1px;
    line-height: 24px;
    color: #FFFFFF;
  }
}