/*********************************************
:: Media DetailPage
********************************************/
.page-media-detail {
    padding: 80px 0 99px;

    .body-section {
        .main-media {
            max-width: 860px;
            width: 67%;

            @media (max-width: $large) {
                width: 60%;
            }

            @media (max-width: $smallish) {
                width: 100%;
            }

            .main-image {
                margin: 0 0 31px;
            }

            .title {
                max-width: 780px;
                width: 90%;
            }

            .logo {
                margin: -1px 5px 0 0;
                max-height: 50px;
                max-width: 56px;

                @media (max-width: $smallish) {
                    position: absolute;
                    right: 0;
                    top: -15px;
                }
            }

            p {
                font-size: 15px;
                letter-spacing: 0.1px;
                line-height: 24px;

                &.date {
                    margin: 0 0 19px;
                }
            }

            h3 {
                margin: 0 0 31px;
                font-size: 30px;
                line-height: 38px;
            }

            .wysiwyg {
                max-width: 780px;
                margin-bottom: 20px;

                h4 {
                    margin: 39px 0 21px;

                    font-size: 22px;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    line-height: 32px;
                }

                img {
                    margin: 20px 0;
                }
            }
        }

        .editor-picks {
            max-width: 420px;
            width: 33%;
            padding: 26px 20px 20px;
            background-color: #F9F9F9;

            @media (max-width: $smallish) {
                width: 100%;
                margin-top: 60px;
            }

            p {
                margin: 0 0 5px;
                font-size: 15px;
                letter-spacing: 0.1px;
                line-height: 24px;
            }

            h3 {
                margin: 0 0 20px;
                font-size: 22px;
                font-weight: 600;
                letter-spacing: 0.2px;
                line-height: 32px;
            }

            .editor-row {
                margin: 0 0 20px;

                &:last-child {
                    margin: 0;
                }

                .body {
                    padding: 31px 30px 30px;
                    background: #fff;

                    @media (max-width: $mediumer) {
                        padding: 20px;
                    }

                    p {
                        margin: 0 0 11px;
                        font-size: 15px;
                        letter-spacing: 0.1px;
                        line-height: 24px;
                    }

                    h4 {
                        margin: 0 0 40px;
                        font-size: 17px;
                        font-weight: 600;
                        letter-spacing: 0.1px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}