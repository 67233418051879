/*********************************************
:: Slick Slideshow
********************************************/
@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: '../../fonts/' !default;
$slick-loader-path: '../../images/' !default;
$slick-font-family: 'Material Icons' !default;

$slick-prev-character: '\e408' !default;
$slick-next-character: '\e409' !default;
$slick-arrow-size: 55px !default;
$slick-arrow-color: #394e54 !default;

$slick-dot-character: '\2022' !default;
$slick-dot-size: 45px !default;
$slick-dot-color: white !default;
$slick-dot-color-active: white !default;

$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.5 !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

/*******************************************/
// Slider

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url('ajax-loader.gif') center center no-repeat;
  }
}

/* Icons */
// @if $slick-font-family == "slick" {
//     @font-face {
//         font-family: "slick";
//         src: slick-font-url("slick.eot");
//         src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
//         font-weight: normal;
//         font-style: normal;
//     }
// }

/*******************************************/
// Arrows

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  z-index: 99;

  display: block;
  // height: 20px;
  // width: 27px;
  padding: 0;

  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  color: transparent;
  cursor: pointer;

  background: transparent;
  border: none;
  outline: none;

  transform: translate(0, -50%);

  &:hover,
  &:focus {
    color: transparent;

    background: transparent;
    outline: none;

    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-family: $slick-font-family;
    font-size: $slick-arrow-size;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
  }
}

.slick-prev {
  left: -20px;

  @media (min-width: $medium) {
    left: -60px;
  }

  &:before {
    content: $slick-prev-character;
  }
}

.slick-next {
  right: -20px;

  @media (min-width: $medium) {
    right: -60px;
  }

  &:before {
    content: $slick-next-character;
  }
}

/*******************************************/
// Dots

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  list-style: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;

  text-align: center;

  li {
    position: relative;

    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;

    button {
      display: block;
      height: 20px;
      width: 20px;
      padding: 5px;

      line-height: 0px;
      font-size: 0px;
      color: transparent;
      cursor: pointer;

      background: transparent;
      border: 0;
      outline: none;

      &:before {
        content: $slick-dot-character;
        position: absolute;
        top: 0;
        left: 0;

        width: 20px;
        height: 20px;

        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;

        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &:hover,
      &:focus {
        outline: none;

        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
    }

    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

/*******************************************/
// Slider

.slick-slider {
  position: relative;

  display: block;

  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;

  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;

  display: block;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir='rtl'] & {
    float: right;
  }

  img {
    display: block;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
