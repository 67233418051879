/*********************************************
:: Becoming a dealer Page
********************************************/
.page-becoming-dealer {

  .section-1 {
    padding: 80px 0 125px;

    @media (max-width: $smallish) {
      padding: 60px 0 80px;
    }

    .content-max-width {
      max-width: 1275px;
    }

    h4 {
      margin: 0 0 20px;
      font-size: 17px;
      font-weight: normal;
      letter-spacing: 0.1px;
      line-height: 26px;
    }

    h2 {
      margin: 0 0 50px;
    }

    .flex {

      .slider {
        max-width: 640px;
        width: 55%;

        @media (max-width: $large) {
          width: 50%;
        }

        @media (max-width: $smallish) {
          width: 100%;
        }
      }

      .text {
        max-width: 530px;
        width: 45%;
        margin-top: -.5px;

        @media (max-width: $large) {
          width: 40%;
        }

        @media (max-width: $smallish) {
          width: 100%;
          margin-top: 20px;
        }

        .wysiwyg {
          margin: 0 0 20px;

          p {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 26px;
          }
        }

        .read-more {
          gap: 7px;

          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 24px;
        }
      }
    }
  }

  .form-section {
    padding: 75px 0 100px;
    background-color: #F9F9F9;

    text-align: center;

    @media (max-width: $smallish) {
      padding: 60px 0 80px;
    }

    .content-narrow-width {
      max-width: 681px;

      h3 {
        margin: 0 0 21px;

        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 38px;
      }

      p {
        margin: 0 0 48.5px;
        font-size: 15px;
        letter-spacing: 0.1px;
        line-height: 24px;
      }
    }

    form {
      background: transparent;

      .button {
        margin-top: 17px;
      }
    }
  }
  /********************************************/

}
